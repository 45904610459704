import * as React from "react";
import PropTypes from "prop-types";
import { getImage } from "gatsby-plugin-image";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const InstructorGrid = ({ instructors }) => (
  <section className="section">
    {instructors.map((item) => (
      <div className="columns is-multiline">
        <div className="column is-12">
          <div className="has-text-centered">
            <div
              style={{
                width: "420px",
                display: "inline-block",
              }}
            >
              <h2>{item.name}</h2>
              <p>{item.text}</p>
            </div>
          </div>
        </div>
        
        <div className="column is-6">
          <div className="has-text-centered">
            <div
              style={{
                width: "420px",
                display: "inline-block",
              }}
            >
              <PreviewCompatibleImage imageInfo={item.img1} />
            </div>
          </div>
        </div>
        <div className="column is-6">
          <div className="has-text-centered">
            <div
              style={{
                width: "420px",
                display: "inline-block",
              }}
            >
              <PreviewCompatibleImage imageInfo={item.img2} />
            </div>
          </div>
        </div>
        <div className="column is-12">
          <div className="has-text-centered">
            <div
              style={{
                width: "420px",
                display: "inline-block",
              }}
            >
              <PreviewCompatibleImage imageInfo={item.img3} />
            </div>
          </div>
        </div>
      </div>
    ))}
  </section>
);

InstructorGrid.propTypes = {
  instructors: PropTypes.arrayOf(
    PropTypes.shape({
      img1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      img2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      img3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      name: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default InstructorGrid;
