
import * as React from "react";
import PropTypes from "prop-types";
import { getImage } from "gatsby-plugin-image";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import FullWidthImage from "./FullWidthImage";

const FeatureFull = ({ gridItems }) => (
  <div className="columns">
    {gridItems.map((item) => (
      <div key={item.text} className="column">
        <section className="section">
          <div className="has-text-centered">
            <PreviewCompatibleImage imageInfo={item}/>
          </div>
          <p>{item.text}</p>
        </section>
      </div>
    ))}
  </div>
);

FeatureFull.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureFull;
